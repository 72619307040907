<template>
    <div class="Subordinate">
        <!-- 请输入搜索关键词 -->
        <div class="SearchBar">
            <div class="inputBox">
                <i class="iconfont icon-search"></i>
                <form v-on:submit.prevent action="" class="formBox">
                    <!-- 输入下属姓名 -->
                    <input :value="value" :placeholder="$t('mx_mobile.common.1665639134210')" type="search" @keyup.enter="toSearch" @input="handleInput" />
                </form>
            </div>
        </div>
        <router-view ref="subordList"></router-view>
    </div>
</template>

<script>
import titleMixin from '../../../mixin/title'
import SubordList from './SubordList/index.vue'
export default {
    name: 'Subordinate',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.workbench.1665632180216') }, // 选择下属/部门
    data() {
        return {
            value: '',
            path: ''
        }
    },
    created() {
        this.setMenu()
    },
    mounted() {
    },
    methods: {
        // 全局快捷新增
        closeChoose() {
            let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.workbench.1584934407018')) // '关闭'
            setMenu.onClick(() => {
                this.$router.push(this.path || '/workbench')
            })
        },
        handleInput(event) {
            this.value = event.target.value
            if (this.value === '') {
                this.$refs.subordList.getList()
                this.$refs.subordList.clearData()
            }
        },
        setMenu() {
            this.closeChoose()
            // this.Global.utils.rightMenu.clearMenu()
        },
        toSearch() {
            this.$refs.subordList.getSearchData(this.value)
        }
    },
    components: {
        'subord-list': SubordList
    },
    watch: {
        $route(to, from) {
            if (from.query.subordKey) {
                this.path = from.fullPath
            }
            let path = this.$route.path
            if (path.indexOf('subordinate') != -1) {
                this.setMenu()
            }
            if (path === '/workbench/subordinate') {
                this.value = ''
                this.$refs.subordList.getList()
                this.$refs.subordList.clearData()
            }
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" >
@import "./zh-cn.less";
</style>
